import React from "react"
import ListItem from "./ListItem"

const Listing = ( {props} ) => {
    let countString = props.totalCount === 1 ? ` post` : ` posts`
    return(
        <article id="main" className="listing">
        <header>
            <h2>{ props.title }</h2>
            <div>{ props.subtext }</div>
        </header>
        <section className="wrapper style5">
            <div className="inner">
                <h4>{props.totalCount + countString}</h4>
                    {props.posts.map(({ node }) => (
                        <ListItem 
                            id = { node.id }
                            node={ node }
                            type={ props.type }
                             />
                    ))}
            </div>

        </section>
    
    </article>
    )
  
}

export default Listing;