import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import Listing from '../components/Listing'

const CategoryPage = ({ location, pageContext, data}) => {
  const { category } = pageContext

        const props = { 
          title: "Category:" + category,
          subtext: "",
          pageContext: pageContext,
          location: location,
          data: data,
          posts: data.allMarkdownRemark.edges,
          totalCount: data.allMarkdownRemark.totalCount,
          type: "allMarkdownRemark"
        }
        return (
            <Layout fullMenu>
                <Listing props={props}/>
            </Layout>
        )
}

export const CategoryQuery = graphql`
query CategoryQuery($category: String) {
  allMarkdownRemark(filter: {fields: {category: {eq: $category}}}, limit: 1000, sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        fields {
          slug
          category
        }
        frontmatter {
          title
          date(fromNow: true)
          tags
          category
        }
        id
        excerpt
      }
    }
    totalCount
  }
}
`

export default CategoryPage;