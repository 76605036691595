import React from 'react';
import { Link } from "gatsby"

const ListItem = ( { node, type, id } ) => {

    let item = type === 'childMarkdownRemark'? node[type] : node;
    let excerpt = item.excerpt ? <div className="excerpt">{item.excerpt}</div> : "";
    let categoryLink = "category/" + item.frontmatter.category;
    return (
    <div key={id} className="list-item">
        <Link 
            to={item.fields.slug}>
            <h3>{item.frontmatter.title}{" "}</h3>
        </Link>
        <div className="item-metadata">
        <span className="category-string">posted in <Link to={categoryLink}>{item.frontmatter.category}</Link></span>
        <span className="date">{item.frontmatter.date}</span>
        </div>

        { excerpt }
    </div>
)}
export default ListItem;